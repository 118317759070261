<template>
  <div class="mian">
    <div class="content">
      <!-- 头 -->
      <div class="top" :style="{backgroundColor:isSkin}">
        <div class="thumb-title">新闻</div>
      </div>
      <!-- 内容 -->
      <div class="content-view">
        <div class="content-left">
          <newItem v-for="(item,index) in items" :key="index" :index="Number(isLeft)" :cur="index" :item="item" @cilckMethod="cilckMethod"></newItem>
        </div>
        <div class="content-right">
          <newList v-for="(item,index) in list" :key="index" :item="item"></newList>
          <div class="page" v-show="pageShow">
            <div class="page1">
                <el-pagination background :current-page="currentPage"
                  :page-size="1" layout="prev, pager, next" :total="totals" @current-change="handleCurrentChange">
                </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newItem from './components/newItem-box'
import newList from './components/newList-box'
export default {
	components:{newItem,newList},
	data() {
		return {
			pageShow:false,
			totals:1,
			currentPage:1,
			pageSize:6,
			items:[{title:'全部'},{title:'公司新闻'},{title:'活动介绍'}],
			cur:0,
			list:[]
		}
	},
	created(){
		this.$store.commit('changClick',1)
		var skin = sessionStorage.getItem('Skin')
		var zskin = sessionStorage.getItem('ZSkin')
		if (skin) {
			this.$store.commit('changSkin',skin)
		}
		if (zskin) {
			this.$store.commit('changZSkin',zskin)
		}
		this.handleCurrentChange(1)
		this.cilckMethod(this.$store.state.isNewIndex)
	},
	computed:{
		isSkin(){
			return this.$store.state.isSkin
		},
		isLeft(){
		  return this.$store.state.isNewIndex
		}
	},
	methods:{
		/**获取新闻列表**/ 
		getNewList(index){
			var that = this
			that.$api.getNotice({
				params:{
					noticeType:index,
					pageNum:that.currentPage,
					pageSize:that.pageSize,
				},
			}).then(res=>{
				if (res.data.code == 0) {
					that.list = res.data.data.list
					that.totals = res.data.data.pages
					if (res.data.data.list.length == 0){
						that.pageShow = false
					}else {
						that.pageShow = true
					}
				}else{
					that.list = []
					that.pageShow = false
				}
			})
		},	
		/**菜单点击按钮**/
		cilckMethod(index){
		console.log(index)
			this.$store.commit('changNew',index)
			sessionStorage.setItem('newLeft',index)
			if (index == 0) {
				this.getNewList(0)
			}else if (index == 1) {
				this.getNewList(2)
			}else if (index == 2) {
				this.getNewList(3)
			}
		},
		/**当前页按钮**/ 
		handleCurrentChange(index) {
			console.log(index);
			this.currentPage = index;
			this.$nextTick(function () {
				/**更换分页按钮皮肤**/
				var array = document.querySelectorAll(".el-pagination.is-background .el-pager li"); 
				if(array && array.length>0){
					array.forEach((item,index1) => {
						if (index == array[index1].innerText) {
							array[index1].style.background  = this.isSkin;
						}else{
							array[index1].style.background  = "#f4f4f5";
						}
					})
				}
			});
		},
	}
}
</script>

<style scoped lang="scss">
.mian{
  display: flex;
  flex-direction: column;
  align-items: center;
  .content{
    width: 1200px;
    margin-top: 50px;
    .top{
      width: 100%;
      height: 55px;
      border-radius: 4px;
      line-height: 55px;
      .thumb-title{
        font-size: 18px;
        color: #FFFFFF;
        margin-left: 12px;
      }
    }
    .content-view{
      width: 1200px;
      display: flex;
      justify-items: center;
      align-items: flex-start;
      .content-left{
        width: 285px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #FCFCFC;
        margin: 20px 0px 20px 0px;
        padding: 10px 0px 20px 0px;
      }
      .content-right{
        padding-left: 20px;
        width: 895px;
        margin: 20px 0px 20px 0px;
        .page{
          width: 896px;
          padding: 20px 20px 100px 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .page1{
            display: flex;
            justify-items: center;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>