<template>
  <div>
      <div v-if="item != null" class="mian" @click="newMethod()">
          <div class="mian-img">
              <img v-if="item != null" :src="item.img" alt="">
          </div>
          <div class="content-view">
              <div class="title" v-if="item != null">{{item.noticeTitle}}</div>
              <div class="content" v-if="item != null">{{item.synopsis}}</div>
              <div class="time" v-if="item != null">{{item.createTime}}</div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    methods:{
        newMethod(){
            this.$router.push({
				path:'/newDetails',
				query:{
					newID:this.item.noticeId,
				}
			});
            this.$store.commit('changClick',1)
        }
    }
}
</script>

<style scoped lang="scss">
.mian{
    cursor: pointer;
    width: 895px;
    height: 184px;
    margin-bottom: 60px;
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
    .mian-img{
        width: 184px;
        height: 100%;
		> img{
			width: 100%;
			height: 100%;
		}
    }
    .content-view{
        padding-left: 20px;
        position: relative;
        .title{
            font-size: 16px;
            line-height: 22px;
            color: #333333;
            height: 25px;
            margin-top: 10px;
        }
        .content{
            font-size: 14px;
            line-height: 20px;
            text-align: justify;
            color: #333333;
            margin-top: 20px;
            height: 90px;
            overflow: hidden;
        }
        .time{
            width: 200px;
            font-size: 12px;
            line-height: 17px;
            height: 20px;
            color: #666666;
            position: absolute;
            top:160px;
            display: flex;
            justify-content: left;
            align-items: center;
        }
    }
}
</style>